import request from '@/utils/request'

// 项目项目类别设计表单
export function getProjectTypeDesignList(params) {
    return request.get( '/api/ProjectTypeDesign/list', {
        params: params
    })
}

// 项目类别设计表单详情
export function getProjectTypeDesignDetail(params) {
    return request.get( '/api/ProjectTypeDesign/detail', {
        params: params
    })
}

// 保存项目类别设计表单
export function saveProjectTypeDesign(params) {
    return request({
        url: '/api/ProjectTypeDesign/save',
        method: 'post',
        data: params
    })
}

// 删除项目类别设计表单
export function deleteProjectTypeDesign(params) {
    return request.get( '/api/ProjectTypeDesign/delete', {
        params: params
    })
}