import router from '@/router/index'
export default {
  namespaced: true,
  state: {
    city: {
      label: '上海',
      value: 'AREA|dbf46d32-7e76-1196'
    }
  },
  mutations: {
    changeTitle (state, payload) {
      state.city = payload
      router.go(-1)
    }
  }
}
