<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar title="添加/修改采购商" left-arrow @click-left="onClickLeft" />
    <!-- 表单组件 -->
    <van-form :model="addForm" ref="dataForm">
      <van-field
              v-model="addForm.cg_name"
              name="cg_name"
              placeholder="请输入采购商名称" label="采购商名称" required clearable
              :rules="[
          { required: true, message: '请填写采购商名称' }
        ]"
      />
      <van-field
              v-model="addForm.cg_phone"
              name="cg_phone"
              placeholder="请输入登录账号" label="登录账号" required clearable
              :rules="[
          { required: true, message: '请填写登录账号' }
        ]"
      />
      <van-field
              v-model="addForm.cg_password"
              name="cg_password"
              placeholder="请输入登录密码，密码长度6位" label="登录密码" required clearable
              :rules="[
          { required: true, message: '请填写登录密码' }
        ]"
      />
      <van-field
              readonly
              clickable
              name="cg_project"
              :value="addForm.cg_project_text"
              label="采购项目"
              placeholder="点击选择采购项目"
              @click="showTypePicker = true" required clearable
      />
      <van-popup v-model="showTypePicker" position="bottom">
        <van-picker
                show-toolbar
                :columns="projectList"
                value-key="pname"
                :default-index="pSelectedIndex"
                @confirm="onTypeConfirm"
                @cancel="showTypePicker = false"
        />
      </van-popup>
      <van-field name="pay_type" label="是否展示">
        <template #input>
          <van-radio-group v-model="addForm.pay_type" direction="horizontal">
            <van-radio name="0">现结</van-radio>
            <van-radio name="1">月结</van-radio>
            <van-radio name="2">其他</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
              v-model="addForm.psort"
              name="psort"
              placeholder="请输入排序" label="排序" clearable
      />
      <div style="margin: 16px;text-align: center;">
        <van-button type="info" @click="onSubmit()">确 认</van-button>
        <van-button color="#cccc" style="margin-left: 20px;" to="/caigouList">返 回</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
    import { getSearchSelectList } from "@/api/project";
    import { getCaigouDetail, saveCaigou } from "@/api/caigou";
    import { Dialog } from 'vant';

    export default {
        name: 'AddProjectTypeDesign',
        data () {
            return {
                addForm: {
                    id: 0,
                    cg_name: '',
                    cg_phone: '',
                    cg_password: '',
                    cg_project: 0,
                    cg_project_text: '',
                    pay_type: '0',
                    psort: 0,
                },
                showTypePicker: false,
                projectList: [],
                field: 'project',
                pSelectedIndex: '0'
            }
        },
        created() {
            this.addForm.id = this.$route.query.id;
            if(this.addForm.id != undefined && this.addForm.id != '' && this.addForm.id != 0 ) {
                //修改获取信息
                this.getDetail();
            } else {
                //新增
                this.getSearchSelectData(0);
            }
        },
        methods: {
            async onSubmit () {
                this.$refs.dataForm.validate().then(() => {
                    // 验证通过
                    if (this.addForm.cg_project == undefined || this.addForm.cg_project == '' || this.addForm.cg_project == 0) {
                        Dialog.alert({message: "请选择采购项目"});
                        return false
                    }
                    saveCaigou(this.addForm).then(resp => {
                        Dialog.alert({message: resp.message}).then(() => {
                            this.$router.push('/caigouList')
                        });
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(error => {
                    console.log(error)
                });
            },
            getDetail () {
                getCaigouDetail({ id: this.addForm.id }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        this.addForm = resp.data;
                        this.getSearchSelectData(resp.data.cg_project);
                    } else {
                        console.log(resp.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            getSearchSelectData (cg_project) {
                getSearchSelectList({ field: this.field }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        this.projectList = resp.data.project_list;
                        //获取默认选中
                        if (resp.data.project_list.length > 0) {
                            resp.data.project_list.forEach((item, index) => {
                                if(item.id == cg_project) {
                                    this.pSelectedIndex = index;
                                }
                            })
                        }
                    } else {
                        console.log(resp.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            onTypeConfirm(value) {
                this.addForm.cg_project_text = value.pname;
                this.addForm.cg_project = value.id;
                this.showTypePicker = false;
            },
            onClickLeft () {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="less" scoped>

</style>
