var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-nav-bar',{attrs:{"title":"添加/修改采购商","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('van-form',{ref:"dataForm",attrs:{"model":_vm.addForm}},[_c('van-field',{attrs:{"name":"cg_name","placeholder":"请输入采购商名称","label":"采购商名称","required":"","clearable":"","rules":[
        { required: true, message: '请填写采购商名称' }
      ]},model:{value:(_vm.addForm.cg_name),callback:function ($$v) {_vm.$set(_vm.addForm, "cg_name", $$v)},expression:"addForm.cg_name"}}),_c('van-field',{attrs:{"name":"cg_phone","placeholder":"请输入登录账号","label":"登录账号","required":"","clearable":"","rules":[
        { required: true, message: '请填写登录账号' }
      ]},model:{value:(_vm.addForm.cg_phone),callback:function ($$v) {_vm.$set(_vm.addForm, "cg_phone", $$v)},expression:"addForm.cg_phone"}}),_c('van-field',{attrs:{"name":"cg_password","placeholder":"请输入登录密码，密码长度6位","label":"登录密码","required":"","clearable":"","rules":[
        { required: true, message: '请填写登录密码' }
      ]},model:{value:(_vm.addForm.cg_password),callback:function ($$v) {_vm.$set(_vm.addForm, "cg_password", $$v)},expression:"addForm.cg_password"}}),_c('van-field',{attrs:{"readonly":"","clickable":"","name":"cg_project","value":_vm.addForm.cg_project_text,"label":"采购项目","placeholder":"点击选择采购项目","required":"","clearable":""},on:{"click":function($event){_vm.showTypePicker = true}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showTypePicker),callback:function ($$v) {_vm.showTypePicker=$$v},expression:"showTypePicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.projectList,"value-key":"pname","default-index":_vm.pSelectedIndex},on:{"confirm":_vm.onTypeConfirm,"cancel":function($event){_vm.showTypePicker = false}}})],1),_c('van-field',{attrs:{"name":"pay_type","label":"是否展示"},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(_vm.addForm.pay_type),callback:function ($$v) {_vm.$set(_vm.addForm, "pay_type", $$v)},expression:"addForm.pay_type"}},[_c('van-radio',{attrs:{"name":"0"}},[_vm._v("现结")]),_c('van-radio',{attrs:{"name":"1"}},[_vm._v("月结")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("其他")])],1)]},proxy:true}])}),_c('van-field',{attrs:{"name":"psort","placeholder":"请输入排序","label":"排序","clearable":""},model:{value:(_vm.addForm.psort),callback:function ($$v) {_vm.$set(_vm.addForm, "psort", $$v)},expression:"addForm.psort"}}),_c('div',{staticStyle:{"margin":"16px","text-align":"center"}},[_c('van-button',{attrs:{"type":"info"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v("确 认")]),_c('van-button',{staticStyle:{"margin-left":"20px"},attrs:{"color":"#cccc","to":"/caigouList"}},[_vm._v("返 回")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }