import Vue from 'vue'
import Vuex from 'vuex'
import home from './modules/home'
import user from './modules/user'
import collect from './modules/collect'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  modules: { home, user, collect }
})
