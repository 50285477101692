import Layout from '@/views/layout.vue'
import Home from '@/views/home.vue'
import CaigouHome from '@/views/caigouHome.vue'
import Login from '@/views/login.vue'
import Project from '@/views/project.vue'
import AddProject from '@/views/addProject.vue'
import ProjectType from '@/views/projectType.vue'
import AddProjectType from '@/views/addProjectType.vue'
import ProjectTypeDesign from '@/views/projectTypeDesign.vue'
import AddProjectTypeDesign from '@/views/addProjectTypeDesign.vue'
import CaigouList from '@/views/caigouList.vue'
import AddCaigou from '@/views/addCaigou.vue'
import KehuList from '@/views/kehuList.vue'
import AddKehu from '@/views/addKehu.vue'

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      { path: 'home', component: Home },
    ]
  },
  {
      path: '/caigouHome',
      component: CaigouHome
  },
  {
    path: '/login',
    component: Login
  },
  {
      path: '/project',
      component: Project
  },
  {
      path: '/addProject',
      name: 'AddProject',
      component: AddProject
  },
  {
      path: '/projectType',
      component: ProjectType
  },
  {
      path: '/addProjectType',
      name: 'AddProjectType',
      component: AddProjectType
  },
  {
      path: '/projectTypeDesign',
      component: ProjectTypeDesign
  },
  {
      path: '/addProjectTypeDesign',
      name: 'AddProjectTypeDesign',
      component: AddProjectTypeDesign
  },
  {
      path: '/caigouList',
      component: CaigouList
  },
  {
      path: '/addCaigou',
      name: 'AddCaigou',
      component: AddCaigou
  },
  {
      path: '/kehuList',
      component: KehuList
  },
  {
      path: '/addKehu',
      name: 'AddKehu',
      component: AddKehu
  }
]

export default routes
