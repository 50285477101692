import { setToken, getToken } from '@/utils/storage'

export default {
  namespaced: true,
  state: {
    token: getToken() || '',
    info: {}
  },
  mutations: {
    setToken (state, payload) {
      state.token = payload
      setToken(payload)
    },
    setInfo (state, payload) {
      payload.avatar = 'http://liufusong.top:8080' + payload.avatar
      state.info = payload
    },
    removeToken (state) {
      state.token = ''
    }
  }
}
