<template>
  <div id="app">
    <div class="welcome_title">欢迎使用，优学尚客户信息管理系统！</div>
    <div class="my_info">
        <van-row>
            <van-col span="12">我的账号：18681003001</van-col>
            <van-col span="12">上次登录：2023/03/09 18:33:00</van-col>
        </van-row>
        <van-row v-if="userType == 'caigou'">
            <van-col span="8">我的身份：采购商</van-col>
            <van-col span="10">采购项目：计算机软件</van-col>
            <van-col span="6"><a href="#" class="link-blue" @click="removeToken">退出登录</a></van-col>
        </van-row>
        <van-row v-else>
            <van-col span="12">我的身份：内部员工</van-col>
            <van-col span="12"><a href="#" class="link-blue" @click="removeToken">退出登录</a></van-col>
        </van-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MyInfo',
  props: {
      userType: String
  },
  async created () {
    //this.setInfo()
  },
  methods: {
      /*async setInfo () {
          const { body } = await userInfo()
          this.$store.commit('user/setInfo', body)
      },*/
      // 退出功能删除token
      removeToken () {
          if (this.token) this.$store.commit('user/removeToken')
          else this.$router.push('/login')
      }
  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
}
#app {
    padding: 10px;
}
.link-blue {
    color: red;
    text-decoration: underline;
}
.welcome_title {
    font-size: 18px;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
}
.my_info {
    font-size: 12px;
    width: 360px;
    margin: 0 auto;
}
@media screen and (max-width: 767.98px) {
    .welcome_title {
        font-size: 26px;
    }
    .my_info {
        font-size: 18px;
    }
}
@media screen and (max-width: 414px) {
    .welcome_title {
        font-size: 18px;
    }
    .my_info {
        font-size: 12px;
    }
}
</style>
