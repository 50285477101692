<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar title="添加/修改表单设计" left-arrow @click-left="onClickLeft" />
    <!-- 表单组件 -->
    <van-form :model="addForm" ref="dataForm">
      <van-field
              v-model="addForm.name"
              name="name"
              placeholder="字母、数字，或组合，不能有特殊字符！" label="字段名称" required clearable
              :rules="[
          { required: true, message: '请填写字段名称' }
        ]"
      />
      <van-field
              v-model="addForm.tip"
              name="tip"
              placeholder="请输入标题" label="标题" required clearable
              :rules="[
          { required: true, message: '请填写标题' }
        ]"
      />
      <van-field
              readonly
              clickable
              name="ptype"
              :value="addForm.ptypetxt"
              label="字段类型"
              placeholder="点击选择字段类型"
              @click="showTypePicker = true" clearable
      />
      <van-popup v-model="showTypePicker" position="bottom">
        <van-picker
                show-toolbar
                :columns="typeList"
                value-key="name"
                :default-index="typeSelectedIndex"
                @confirm="onTypeConfirm"
                @cancel="showTypePicker = false"
        />
      </van-popup>
      <van-field name="must_input" label="是否必填">
        <template #input>
          <van-radio-group v-model="addForm.must_input" direction="horizontal">
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field name="status" label="是否展示">
        <template #input>
          <van-radio-group v-model="addForm.status" direction="horizontal">
            <van-radio name="0">是</van-radio>
            <van-radio name="1">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
              v-model="addForm.dsort"
              name="psort"
              placeholder="请输入排序" label="排序" clearable
      />
      <div style="margin: 16px;text-align: center;">
        <van-button type="info" @click="onSubmit()">确 认</van-button>
        <van-button color="#cccc" style="margin-left: 20px;" @click="returnLast()">返 回</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
    import { getProjectTypeDesignDetail, saveProjectTypeDesign } from "@/api/projectTypeDesign";
    import { Dialog } from 'vant';

    export default {
        name: 'AddProjectTypeDesign',
        data () {
            return {
                addForm: {
                    id: 0,
                    ptype_id: 0,
                    name: '',
                    tip: '',
                    ptype: 0,
                    ptypetxt: '文本',
                    must_input: '0',
                    status: '0',
                    dsort: 0,
                },
                cg_id: 0,
                showTypePicker: false,
                typeList: [{'id': 0, 'name':'文本'}, {'id': 1, 'name':'图片'}],
                typeSelectedIndex: '0'
            }
        },
        created() {
            this.cg_id = this.$route.query.cg_id
            this.addForm.id = this.$route.query.id
            this.addForm.ptype_id = this.$route.query.ptype_id
            if(this.addForm.id != undefined && this.addForm.id != '' && this.addForm.id != 0 ) {
                //修改获取信息
                this.getDetail();
            }
        },
        methods: {
            async onSubmit () {
                this.$refs.dataForm.validate().then(() => {
                    // 验证通过
                    saveProjectTypeDesign(this.addForm).then(resp => {
                        Dialog.alert({message: resp.message}).then(() => {
                            this.$router.push({ path: '/projectTypeDesign', query: { ptype_id: this.addForm.ptype_id } })
                        });
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(error => {
                    console.log(error)
                });
            },
            getDetail () {
                getProjectTypeDesignDetail({ id: this.addForm.id }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        this.addForm = resp.data;
                        this.typeList.forEach((item, index) => {
                            if(item.id == this.addForm.ptype) {
                                this.typeSelectedIndex = index;
                            }
                        })
                    } else {
                        console.log(resp.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            onTypeConfirm(value) {
                this.addForm.ptypetxt = value.name;
                this.addForm.ptype = value.id;
                this.showTypePicker = false;
            },
            returnLast() {
                this.$router.push({ path: '/projectTypeDesign', query: { ptype_id: this.addForm.ptype_id, cg_id: this.cg_id } })
            },
            onClickLeft () {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="less" scoped>

</style>
