import request from '@/utils/request'

// 采购商列表
export function getCaigouList(params) {
    return request.get( '/api/Caigou/list', {
        params: params
    })
}

// 采购商详情
export function getCaigouDetail(params) {
    return request.get( '/api/Caigou/detail', {
        params: params
    })
}

// 保存采购商
export function saveCaigou(params) {
    return request({
        url: '/api/Caigou/save',
        method: 'post',
        data: params
    })
}

// 启用/禁用采购商
export function enableCaigou(params) {
    return request.get( '/api/Caigou/enable', {
        params: params
    })
}

// 删除采购商
export function deleteCaigou(params) {
    return request.get( '/api/Caigou/delete', {
        params: params
    })
}