import Vue from 'vue'
import VueRouter from 'vue-router'
// import { getToken } from '@/utils/storage'
import routes from './routes.js'
import store from '@/store/index.js'
Vue.use(VueRouter)

const router = new VueRouter({
  routes
})
// push
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}
const whiteList = ['/login',  '/home', '/project', '/projectType', '/projectTypeDesign', '/caigouHome', '/caigouList', '/kehuList', '/addProject', '/addProjectType', '/addProjectTypeDesign', '/addCaigou', '/addKehu']
router.beforeEach((to, from, next) => {
  const token = store.state.user.token
  if (token) {
    next()
  } else {
    //   没有token的看是不是访问白名单页面是的就放行否则跳转到登录页面
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }
})
export default router
