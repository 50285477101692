<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar :title="(cg_id != 0 && cg_id != '' && cg_id != undefined && this.cg_id != 'undefined') ? '采购商表单设计' : '类别表单设计'" left-arrow @click-left="onClickLeft" />
    <MyInfo :userType="userType"></MyInfo>

    <BreadCrumbs :list="breadList"></BreadCrumbs>
    <van-list
            v-if="tableData.list.length"
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad"
    >
      <van-cell v-for="(item, index) in tableData.list" :key="index">
        <template>
          <van-row class="list-font list-border">
            <van-col span="18">
              <div>字段ID：{{ item.id }}</div>
              <div>字段名称：{{ item.name }}</div>
              <div>标题：{{ item.tip }}</div>
              <div>字段类型：{{ item.ptypetxt }}</div>
              <div>是否必填：<span v-if="item.must_input == 1" class="font_green">是</span><span v-else>否</span></div>
              <div>是否显示：<span v-if="item.status == 0">是</span><span v-else class="font_red">否</span></div>
            </van-col>
            <van-col span="6">
              <a class="operator_a font_red" @click="onDelete(index, item.id)">删除</a><br>
              <a class="operator_a font_green" @click="onModify(item.id)">修改</a>
            </van-col>
          </van-row>
        </template>
      </van-cell>
    </van-list>
    <van-empty description="暂无数据" v-else />

  </div>
</template>

<script>
    import { getProjectTypeDetail } from "@/api/projectType";
    import { getProjectTypeDesignList, deleteProjectTypeDesign } from "@/api/projectTypeDesign";
    import MyInfo from '@/components/MyInfo'
    import BreadCrumbs from '@/components/BreadCrumbs'
    import { Dialog } from 'vant';
    export default {
        name: 'ProjectTypeDesign',
        components: { MyInfo, BreadCrumbs },
        data () {
            return {
                cg_id: 0,
                userType: "",
                ptype_id: 0,
                page: 1,
                limit: 20,
                tableData: {
                    list: [],
                    total: 0,
                },
                loading: false,
                finished: false,
                timer: null,
                breadList: [
                    { name:'表单设计', path: '/projectTypeDesign?ptype_id={ptype_id}&cg_id={cg_id}', active:1 },
                    { name:'添加字段', path: '/addProjectTypeDesign?ptype_id={ptype_id}&cg_id={cg_id}', active:0 },
                ],
            }
        },
        computed: {
        },
        async created () {
            this.cg_id = this.$route.query.cg_id
            this.ptype_id = this.$route.query.ptype_id
            //替换字段
            let path1 = this.breadList[0].path;
            path1 = path1.replace("{ptype_id}", this.ptype_id)
            this.breadList[0].path = path1.replace("{cg_id}", this.cg_id)
            let path2 = this.breadList[1].path;
            path2 = path2.replace("{ptype_id}", this.ptype_id)
            this.breadList[1].path = path2.replace("{cg_id}", this.cg_id)
            if(this.ptype_id != '' || this.ptype_id != 0 ) {
                //修改获取信息
                this.getDetail();
            }
            if(this.cg_id != 0 && this.cg_id != '' && this.cg_id != undefined && this.cg_id != 'undefined') {
                this.userType = "caigou";
            }
            this.getListData();
        },
        methods: {
            onClickLeft () {
                this.$router.go(-1)
            },
            getDetail () {
                getProjectTypeDetail({ id: this.ptype_id }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        this.breadList[0].name = resp.data.tname + '--表单设计';
                    } else {
                        console.log(resp.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            getListData() {
                const param = {
                    ptype_id: this.ptype_id,
                    page: this.page,
                    limit: this.limit,
                }
                getProjectTypeDesignList(param).then(resp => {
                    // 加载状态结束
                    this.loading = false;
                    this.tableData.total = resp.total;
                    if (resp.data.length > 0) {
                        resp.data.forEach((item) => {
                            this.tableData.list.push(item);
                        })
                    } else {
                        this.finished = true;
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            onLoad() {
                this.loading = false;
                if(!this.timer) {
                    this.timer = setTimeout(() => {
                        this.page++;
                        this.getListData();
                        this.timer = null;
                    }, 1000)
                }
            },
            onDelete(index, val) {
                Dialog.confirm({
                    title: '提示',
                    message: '确定删除？',
                }).then(() => {
                    // on confirm
                    deleteProjectTypeDesign({ id: val }).then(resp => {
                        if(resp.code == 200 || resp.code == 0) {
                            Dialog.alert({message: resp.message});
                            this.tableData.list.splice(index, 1);
                        } else {
                            Dialog.alert({ message: resp.message });
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(() => {
                    // on cancel
                });
            },
            onModify (val){
                this.$router.push({ path: '/addProjectTypeDesign', query: { id: val, ptype_id: this.ptype_id, cg_id: this.cg_id } })
            }
        }
    }
</script>

<style lang="less" scoped>
  * {
    box-sizing: border-box;
  }
  .list-border {
    border: 1px solid #D3E3FD;
    border-radius: 15px;
    padding-left: 10px;
  }

  @media screen and (max-width: 767.98px) {
    .list-font {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 414px) {
    .list-font {
      font-size: 12px;
    }
  }
  .operator_a {
    text-decoration: underline;
  }
  .font_red {
    color: rgba(247, 40, 40, 1);
  }
  .font_blue {
    color: rgba(42, 130, 228, 1);
  }
  .font_green {
    color: rgba(28, 199, 96, 1);
  }
</style>
