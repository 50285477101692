import requset from '@/utils/request.js'

// 导出登录请求方法
export const login = function (data) {
  return requset({ url: '/user/login', data, method: 'POST' })
}

// 用户信息资料
export const userInfo = function (params) {
  return requset({ url: '/user', params })
}
