import request from '@/utils/request'

// 项目类别列表
export function getProjectTypeList(params) {
    return request.get( '/api/Projecttype/list', {
        params: params
    })
}

// 项目类别详情
export function getProjectTypeDetail(params) {
    return request.get( '/api/Projecttype/detail', {
        params: params
    })
}

// 保存项目类别
export function saveProjectType(params) {
    return request({
        url: '/api/Projecttype/save',
        method: 'post',
        data: params
    })
}

// 删除项目类别
export function deleteProjectType(params) {
    return request.get( '/api/Projecttype/delete', {
        params: params
    })
}