var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('van-nav-bar',{attrs:{"title":"账号登录","left-arrow":""},on:{"click-left":_vm.onClickLeft}}),_c('van-form',{ref:"dataForm",attrs:{"validate-trigger":"onSubmit"},on:{"submit":_vm.onSubmit}},[_c('van-field',{attrs:{"name":"identity","label":"身份","required":""},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('van-radio-group',{attrs:{"direction":"horizontal"},model:{value:(_vm.identity),callback:function ($$v) {_vm.identity=$$v},expression:"identity"}},[_c('van-radio',{attrs:{"name":"1"}},[_vm._v("内部员工")]),_c('van-radio',{attrs:{"name":"2"}},[_vm._v("采购商")])],1)]},proxy:true}])}),_c('van-field',{attrs:{"name":"username","placeholder":"请输入账号","label":"账号","required":"","rules":[
        { required: true, message: '请填写账号', pattern: /^\w{5,}$/ }
      ]},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c('van-field',{attrs:{"type":"password","name":"password","placeholder":"请输入密码","label":"密码","required":"","rules":[
        { required: true, message: '请填写密码', pattern: /^\w{5,}$/ }
      ]},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('van-row',[_c('van-col',{attrs:{"span":"8"}},[_c('van-field',{attrs:{"name":"verifyCode","placeholder":"验证码","rules":[
        { required: true, message: '请填写验证码', pattern: /^\w{4,}$/ }
      ]},model:{value:(_vm.verifyCode),callback:function ($$v) {_vm.verifyCode=$$v},expression:"verifyCode"}})],1),_c('van-col',{attrs:{"span":"8"}},[_c('vue-verify-code',{ref:"verifyCode",attrs:{"config":_vm.config},on:{"success":_vm.onVerifySuccess,"error":_vm.onVerifyError}}),_c('button',{on:{"click":_vm.onRefreshClick}},[_vm._v("刷新")])],1)],1),_c('div',{staticStyle:{"margin":"16px"}},[_c('van-button',{attrs:{"block":"","type":"info","native-type":"submit"}},[_vm._v("登录")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }