<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar title="添加/修改项目类别" left-arrow @click-left="onClickLeft" />
    <!-- 表单组件 -->
    <van-form :model="addForm" ref="dataForm">
      <van-field
              v-model="addForm.tname"
              name="tname"
              placeholder="请输入项目类别名称" label="项目类别名称" required clearable
              :rules="[
          { required: true, message: '请填写项目类别名称' }
        ]"
      />
      <van-field
              v-model="addForm.tsort"
              name="tsort"
              placeholder="请输入排序" label="排序" clearable
      />
      <div style="margin: 16px;text-align: center;">
        <van-button type="info" @click="onSubmit()">确 认</van-button>
        <van-button color="#cccc" style="margin-left: 20px;" to="/projectType">返 回</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
    import { getProjectTypeDetail, saveProjectType } from "@/api/projectType";
    import { Dialog } from 'vant';

    export default {
        name: 'AddProjectType',
        data () {
            return {
                addForm: {
                    id: 0,
                    tname: '',
                    tsort: 0,
                },
            }
        },
        created() {
            this.addForm.id = this.$route.query.id
            if(this.addForm.id != undefined && this.addForm.id != '' && this.addForm.id != 0 ) {
                //修改获取信息
                this.getDetail();
            }
        },
        methods: {
            async onSubmit () {
                this.$refs.dataForm.validate().then(() => {
                    // 验证通过
                    saveProjectType(this.addForm).then(resp => {
                        Dialog.alert({message: resp.message}).then(() => {
                            this.$router.push('/projectType')
                        });
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(error => {
                    console.log(error)
                });
            },
            getDetail () {
                getProjectTypeDetail({ id: this.addForm.id }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        this.addForm = resp.data;
                    } else {
                        console.log(resp.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            onClickLeft () {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="less" scoped>

</style>
