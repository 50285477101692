<template>
    <div>
        <router-view></router-view>

        <!--<van-tabbar route active-color="#21b97a" inactive-color="#999">-->
            <!--<van-tabbar-item to="/home" icon="wap-home-o">首页</van-tabbar-item>-->
            <!--<van-tabbar-item to="/find" icon="search">找房</van-tabbar-item>-->
            <!--<van-tabbar-item to="/info" icon="description">资讯</van-tabbar-item>-->
            <!--<van-tabbar-item to="/user" icon="contact">我的</van-tabbar-item>-->
        <!--</van-tabbar>-->
    </div>
</template>

<script>
export default {
    name: 'LayoutIndex',
}
</script>

<style lang="less" scoped></style>
