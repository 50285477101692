<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar title="添加/修改学员" left-arrow @click-left="onClickLeft" />
    <!-- 表单组件 -->
    <van-form :model="addForm" ref="dataForm">
      <van-field
              readonly
              clickable
              name="xy_source"
              :value="addForm.xy_source_text"
              label="采购商名称"
              placeholder="点击选择采购商名称"
              @click="showTypePicker = true" required
      />
      <van-field v-model="addForm.cg_project_text" label="项目名称" readonly/>
      <van-popup v-model="showTypePicker" position="bottom" v-if="addForm.xy_source == '' || addForm.xy_source == 0|| addForm.xy_source==undefined">
        <van-picker
                show-toolbar
                :columns="caigouList"
                value-key="cg_name"
                :default-index="caigouSelectedIndex"
                @confirm="onTypeConfirm"
                @cancel="showTypePicker = false"
        />
      </van-popup>
      <van-field
              v-model="addForm.xy_name"
              name="xy_name"
              placeholder="请输入学员姓名" label="学员姓名" required clearable
              :rules="[
          { required: true, message: '请填写学员姓名' }
        ]"
      />
      <div v-for="(item, index) in designList" :key="index">
        <div v-if="(item.ptype == 1)">
          <van-field :name="item.name" :label="item.tip" required v-if="(item.must_input == 1)">
            <template #input>
              <van-uploader v-model="addForm[item.name]" :max-size="2048 * 1024" :max-count="1" @oversize="onOversize"/>
            </template>
          </van-field>
          <van-field :name="item.name" :label="item.tip" v-else>
            <template #input>
              <van-uploader v-model="addForm[item.name]" :max-size="2048 * 1024" :max-count="1" @oversize="onOversize"/>
            </template>
          </van-field>
        </div>
        <div v-else>
          <van-field
                  v-model="addForm[item.name]"
                  :name="item.name"
                  :placeholder="'请输入'+item.tip" :label="item.tip" required clearable
                  :rules="[
          { required: true, message: '请填写'+item.tip }
        ]"
                  v-if="(item.must_input == 1)"/>
          <van-field
                  v-model="addForm[item.name]"
                  :name="item.name"
                  :placeholder="'请输入'+item.tip" :label="item.tip" clearable v-else/>
        </div>
      </div>
      <!-- 表单设计字段 -->
      <div style="margin: 16px;text-align: center;">
        <van-button type="info" @click="onSubmit()">确 认</van-button>
        <van-button color="#cccc" style="margin-left: 20px;" :to="(cg_id != 0 && cg_id != '' && cg_id != undefined) ? '/caigouHome' : '/kehuList'">返 回</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
    import { getSearchSelectList } from "@/api/project";
    import { getKehuDetail, saveKehu } from "@/api/kehu";
    import { getCaigouDetail } from "@/api/caigou";
    import { getProjectTypeDesignList } from "@/api/projectTypeDesign";
    import { Dialog, Toast } from 'vant';

    export default {
        name: 'AddKehu',
        data () {
            return {
                addForm: {
                    id: 0,
                    xy_name: '',
                    xy_source: '',
                    cg_project: 0, //项目id
                    cg_ptype: 0, //项目类别id
                    xy_source_text: '',
                    cg_project_text: '',
                },
                cg_id: 0,
                field: 'caigou',
                showTypePicker: false,
                caigouSelectedIndex: '0',
                caigouList: [],
                designList: [],
                fileList: [{ url: '' }],
                htmlCode: '',
            }
        },
        created() {
            this.addForm.id = this.$route.query.id;
            this.cg_id = this.$route.query.cg_id;
            this.addForm.xy_source = this.$route.query.cg_id;
            if(this.addForm.id != undefined && this.addForm.id != '' && this.addForm.id != 0 ) {
                //修改获取信息
                this.getDetail();
            } else if(this.addForm.xy_source != undefined && this.addForm.xy_source != '' && this.addForm.xy_source != 0 ) {
                //获取采购信息
                this.getCaigouDetail();
            }
            this.getSearchSelectData();
        },
        methods: {
            async onSubmit () {
                console.log(this.addForm)
                this.$refs.dataForm.validate().then(() => {
                    // 验证通过
                    if (this.addForm.xy_source == undefined || this.addForm.xy_source == '' || this.addForm.xy_source == 0) {
                        Dialog.alert({message: "请选择采购商"});
                        return false
                    }
                    saveKehu(this.addForm).then(resp => {
                        Dialog.alert({message: resp.message}).then(() => {
                            this.$router.push('/kehuList')
                        });
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(error => {
                    console.log(error)
                });
            },
            getCaigouDetail () {
                getCaigouDetail({ id: this.addForm.xy_source }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        this.addForm.xy_source_text = resp.data.cg_name;
                        this.addForm.cg_project = resp.data.cg_project;
                        this.addForm.cg_ptype = resp.data.cg_ptype;
                        this.addForm.cg_project_text = resp.data.cg_project_text;
                    } else {
                        console.log(resp.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            getDetail () {
                getKehuDetail({ id: this.addForm.id }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        this.addForm = resp.data;
                    } else {
                        console.log(resp.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            getSearchSelectData () {
                getSearchSelectList({ field: this.field }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        this.caigouList = resp.data.caigou_list;
                        //获取默认选中
                        if (resp.data.caigou_list.length > 0) {
                            resp.data.caigou_list.forEach((item, index) => {
                                if(item.id == this.addForm.xy_source) {
                                    this.caigouSelectedIndex = index;
                                }
                            })
                        }
                    } else {
                        console.log(resp.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            onTypeConfirm(value) {
                this.addForm.xy_source_text = value.cg_name;
                this.addForm.xy_source = value.id;
                this.showTypePicker = false;

                getCaigouDetail({ id: value.id }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        this.addForm.cg_project_text = resp.data.cg_project_text;
                        this.addForm.cg_project = resp.data.cg_project;
                        this.addForm.cg_ptype = resp.data.cg_ptype;
                    } else {
                        console.log(resp.message)
                    }
                }).catch(error => {
                    console.log(error)
                })

                getProjectTypeDesignList({ cg_id: value.id }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        this.designList = resp.data;
                        resp.data.forEach((item) => {
                            let tt = {};
                            tt[item.name] = '';
                            this.addForm.push(tt)
                        })
                    } else {
                        console.log(resp.message)
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            onOversize() {
                Toast('文件大小不能超过 2M');
            },
            onClickLeft () {
                this.$router.go(-1)
            }
        }
    }
</script>

<style lang="less" scoped>

</style>
