<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar title="首页"/>
    <MyInfo></MyInfo>

    <van-row type="flex" justify="space-around">
      <van-col span="8"><van-button type="info" to="/project">项目管理</van-button></van-col>
      <van-col span="8"><van-button type="info" to="/kehuList">学员管理</van-button></van-col>
    </van-row>
    <van-row type="flex" justify="space-around">
      <van-col span="8"><van-button type="info" to="/caigouList">采购商管理</van-button></van-col>
      <van-col span="8"><van-button type="info" to="/my">账户设置</van-button></van-col>
    </van-row>
    <van-row type="flex" justify="space-around">
      <van-col span="12"><van-button type="info" to="/caigouHome">采购商登录首页</van-button></van-col>
    </van-row>

    <!--<van-grid-->
            <!--:column-num="2"-->
            <!--:border="false"-->
            <!--icon-size="20px"-->
            <!--:clickable="true"-->
    <!--&gt;-->
      <!--<van-grid-item icon="wap-home-o" text="项目管理" to="/hire" />-->
      <!--<van-grid-item icon="wap-home-o" text="学员管理" to="/hire" />-->
      <!--<van-grid-item icon="manager-o" text="采购商管理" to="/hire"  />-->
      <!--<van-grid-item icon="manager-o" text="账户设置" to="/hire"  />-->
    <!--</van-grid>-->
  </div>
</template>

<script>
    import MyInfo from '@/components/MyInfo'
    export default {
        name: 'UserHome',
        components: { MyInfo },
        computed: {

        },
        async created () {

        },
        methods: {

        }
    }
</script>

<style lang="less" scoped>
  * {
    box-sizing: border-box;
  }
  .van-row {
    margin-top:20px;
  }
  .van-col {
    /*background-color: rgba(204, 204, 204, 1);*/
    background-color: #1989fa;
    text-align: center;
    line-height: 40px;
    border-radius: 2px;
    color: #fff;
  }
  .van-col-active {
    background-color: rgba(42, 130, 228, 1);
  }
</style>
