export default {
  namespaced: true,
  state: {
    flag: false
  },
  mutations: {
    updateFlag (state, payload) {
      state.flag = payload
    }
  }
}
