import request from '@/utils/request'

// 项目列表
export function getProjectList(params) {
    return request.get( '/api/Project/list', {
        params: params
    })
}

// 项目详情
export function getProjectDetail(params) {
    return request.get( '/api/Project/detail', {
        params: params
    })
}

// 保存项目
export function saveProject(params) {
    return request({
        url: '/api/Project/save',
        method: 'post',
        data: params
    })
}

// 删除项目
export function deleteProject(params) {
    return request.get( '/api/Project/delete', {
        params: params
    })
}

// 搜索条件列表
export function getSearchSelectList(params) {
    return request.get( '/api/Project/getSelectList', {
        params: params
    })
}