// 封装token的属性名
const TOKEN_KEY = 'hm_zf_token'

export const getToken = function () {
  return localStorage.getItem(TOKEN_KEY)
}
export const setToken = function (data) {
  return localStorage.setItem(TOKEN_KEY, data)
}
export const removeToken = function () {
  return localStorage.removeItem(TOKEN_KEY)
}
