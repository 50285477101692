<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar title="项目管理" left-arrow @click-left="onClickLeft" />
    <MyInfo></MyInfo>

    <BreadCrumbs :list="breadList"></BreadCrumbs>
    <van-list
            v-if="tableData.list.length"
            v-model="loading"
            :finished="finished"
            :immediate-check="false"
            finished-text="没有更多了"
            @load="onLoad"
    >
      <van-cell v-for="(item, index) in tableData.list" :key="index">
        <template>
          <van-row class="list-font list-border">
            <van-col span="18">
              <div>项目ID：{{ item.id }}</div>
              <div>项目名称：{{ item.pname }}</div>
              <div>类别：{{ item.ptypetxt }}</div>
              <div>联系人：{{ item.contact }}</div>
              <div>添加时间：{{ item.create_time }}</div>
            </van-col>
            <van-col span="6">
              <a class="operator_a font_blue" @click="viewDetail(item.id)">查看详情</a><br>
              <a class="operator_a font_red" @click="onDelete(index, item.id)">删除</a><br>
              <a class="operator_a font_green" @click="onModify(item.id)">修改</a>
            </van-col>
          </van-row>
        </template>
      </van-cell>
    </van-list>
    <van-empty description="暂无数据" v-else />

  </div>
</template>

<script>
    import { getProjectList, getProjectDetail, deleteProject } from "@/api/project";
    import MyInfo from '@/components/MyInfo'
    import BreadCrumbs from '@/components/BreadCrumbs'
    import { Dialog } from 'vant';
    export default {
        name: 'Project',
        components: { MyInfo, BreadCrumbs },
        data () {
            return {
                page: 1,
                limit: 20,
                tableData: {
                    list: [],
                    total: 0,
                },
                loading: false,
                finished: false,
                timer: null,
                breadList: [
                    { name:'项目管理', path: '/project', active:1 },
                    { name:'添加项目', path: '/addProject', active:0 },
                    { name:'类别管理', path: '/projectType', active:0 },
                ]
            }
        },
        computed: {
        },
        async created () {
            this.getListData();
        },
        methods: {
            getListData() {
                const param = {
                    page: this.page,
                    limit: this.limit,
                }
                getProjectList(param).then(resp => {
                    // 加载状态结束
                    this.loading = false;
                    this.tableData.total = resp.total;
                    if (resp.data.length > 0) {
                        resp.data.forEach((item) => {
                            this.tableData.list.push(item);
                        })
                    } else {
                        this.finished = true;
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            onLoad() {
                this.loading = false;
                if(!this.timer) {
                    this.timer = setTimeout(() => {
                        this.page++;
                        this.getListData();
                        this.timer = null;
                    }, 1000)
                }
            },
            onClickLeft () {
                this.$router.go(-1)
            },
            viewDetail (val) {
                getProjectDetail({ id: val }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        Dialog.alert({
                            title: '详情',
                            message: '<p style="text-align: left;">项目ID：' + resp.data.id + '</p>' +
                            '<p style="text-align: left;">项目名称：' + resp.data.pname + '</p>' +
                            '<p style="text-align: left;">项目类别：' + resp.data.ptypetxt + '</p>' +
                            '<p style="text-align: left;">所属供应商：' + resp.data.gtypetxt + '</p>' +
                            '<p style="text-align: left;">联系人：' + resp.data.contact + '</p>' +
                            '<p style="text-align: left;">手机：' + resp.data.phone + '</p>' +
                            '<p style="text-align: left;">排序：' + resp.data.psort + '</p>' +
                            '<p style="text-align: left;">添加时间：' + resp.data.create_time + '</p>',
                        });
                    } else {
                        Dialog.alert({ message: resp.message });
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            onDelete(index, val) {
                Dialog.confirm({
                    title: '提示',
                    message: '确定删除？',
                }).then(() => {
                    // on confirm
                    deleteProject({ id: val }).then(resp => {
                        if(resp.code == 200 || resp.code == 0) {
                            Dialog.alert({message: resp.message});
                            this.tableData.list.splice(index, 1);
                        } else {
                            Dialog.alert({ message: resp.message });
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(() => {
                    // on cancel
                });
            },
            onModify (val){
                this.$router.push({ path: '/addProject', query: { id: val } })
                //this.$router.push({name: 'AddProject', params: {id: 2}});
            }
        }
    }
</script>

<style lang="less" scoped>
  * {
    box-sizing: border-box;
  }
  .list-border {
    border: 1px solid #D3E3FD;
    border-radius: 15px;
    padding-left: 10px;
  }

  @media screen and (max-width: 767.98px) {
    .list-font {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 414px) {
    .list-font {
      font-size: 12px;
    }
  }
  .operator_a {
    text-decoration: underline;
  }
  .font_red {
    color: rgba(247, 40, 40, 1);
  }
  .font_blue {
    color: rgba(42, 130, 228, 1);
  }
  .font_green {
    color: rgba(28, 199, 96, 1);
  }
</style>
