import request from '@/utils/request'

// 学员列表
export function getKehuList(params) {
    return request.get( '/api/Kehu/list', {
        params: params
    })
}

// 学员详情
export function getKehuDetail(params) {
    return request.get( '/api/Kehu/detail', {
        params: params
    })
}

// 保存学员
export function saveKehu(params) {
    return request({
        url: '/api/Kehu/save',
        method: 'post',
        data: params
    })
}

// 删除学员
export function deleteKehu(params) {
    return request.get( '/api/Kehu/delete', {
        params: params
    })
}