<template>
  <div class="breadcrumbs">
    <span style="margin-left: 5px;" v-for="(item, index) in list" :key="index">
      <van-button v-if="item.active == 1" plain type="info" size="mini">{{ item.name }}</van-button>
      <van-button v-else type="info" size="mini" :to="item.path">{{ item.name }}</van-button>
    </span>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumbs',
  props: {
    list: Array
  },

  methods: {

  }
}
</script>

<style lang="less" scoped>
* {
  margin: 0;
}
.breadcrumbs {
  padding: 10px 0px 0px 10px;
}
</style>
