<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar title="账号登录" left-arrow @click-left="onClickLeft" />
    <!-- 表单组件 -->
    <van-form @submit="onSubmit" validate-trigger="onSubmit" ref="dataForm">
      <van-field name="identity" label="身份" required>
        <template #input>
          <van-radio-group v-model="identity" direction="horizontal">
            <van-radio name="1">内部员工</van-radio>
            <van-radio name="2">采购商</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-model="username"
        name="username"
        placeholder="请输入账号" label="账号" required
        :rules="[
          { required: true, message: '请填写账号', pattern: /^\w{5,}$/ }
        ]"
      />
      <van-field
        v-model="password"
        type="password"
        name="password"
        placeholder="请输入密码" label="密码" required
        :rules="[
          { required: true, message: '请填写密码', pattern: /^\w{5,}$/ }
        ]"
      />
      <van-row>
        <van-col span="8">
          <van-field name="verifyCode" v-model="verifyCode" placeholder="验证码"
                     :rules="[
          { required: true, message: '请填写验证码', pattern: /^\w{4,}$/ }
        ]"></van-field>
        </van-col>
        <van-col span="8">
          <vue-verify-code ref="verifyCode" :config="config" @success="onVerifySuccess" @error="onVerifyError"></vue-verify-code>
          <button @click="onRefreshClick">刷新</button>
        </van-col>
      </van-row>
      <div style="margin: 16px">
        <van-button block type="info" native-type="submit">登录</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { login } from '@/api/user'
import { setToken } from '@/utils/storage'
import VueVerifyCode from 'vue-verify-code';

export default {
  name: 'login',
  components: {
      VueVerifyCode
  },
  data () {
    return {
      identity: '1',
      username: '',
      password: '',
      verifyCode: '',
      config: {
          mode: 'math', // 验证码类型：math（算术验证码），char（字符验证码）
          length: 4, // 验证码长度
          width: 200, // 验证码宽度
          height: 50, // 验证码高度
          font_size: 30, // 字体大小
      }
    }
  },
  created() {

  },
  methods: {
    async onSubmit (values) {
      const data = await login(values)
      setToken(data.body.token)
      this.$store.commit('user/setToken', data.body.token)
      this.$router.push('/')
    },
    onClickLeft () {
        this.$router.go(-1)
    },
    onVerifySuccess() {
        console.log('验证成功');
    },
    onVerifyError() {
        console.log('验证失败');
    },
    onRefreshClick() {
        this.$refs.verifyCode.refresh();
    }
  }
}
</script>

<style lang="less" scoped>
.van-nav-bar {
  background-color: #21b97a;
  margin-bottom: 21px;
  /deep/ .van-nav-bar__content {
    height: 45px;
  }
  /deep/.van-icon {
    color: #fff;
  }
  /deep/ .van-nav-bar__title {
    color: #fff;
    font-size: 18px;
  }
}

/deep/.van-field__control {
  color: #000;
}
.van-button--info {
  background-color: #21b97a;
}
</style>
