<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar title="首页"/>
    <MyInfo :userType="userType"></MyInfo>

    <BreadCrumbs :list="breadList"></BreadCrumbs>
    <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
    >
      <van-cell v-for="(item, index) in tableData.list" :key="index">
        <template>
          <van-row class="list-font list-border">
            <van-col span="18">
              <div>学员ID：{{ item.id }}</div>
              <div>学员姓名：{{ item.xy_name }}</div>
              <div>添加时间：{{ item.create_time }}</div>
              <div>项目名称：{{ item.xy_source_text }}</div>
              <div>资料确认：<span v-if="item.is_confirm == 1">已确认</span><span class="font_green" v-else>待确认</span></div>
            </van-col>
            <van-col span="6">
              <a class="operator_a font_blue" @click="viewDetail(item.id)">查看详情</a><br>
              <a class="operator_a font_red" @click="onDelete(index, item.id)">删除</a><br>
              <a class="operator_a font_green" @click="onModify(item.id)">修改</a>
            </van-col>
          </van-row>
        </template>
      </van-cell>
    </van-list>

  </div>
</template>

<script>
    import { getKehuList, getKehuDetail, deleteKehu } from "@/api/kehu";
    import MyInfo from '@/components/MyInfo'
    import BreadCrumbs from '@/components/BreadCrumbs'
    import { Dialog } from 'vant';
    export default {
        name: 'CaigouHome',
        components: { MyInfo, BreadCrumbs },
        data () {
            return {
                page: 1,
                limit: 20,
                tableData: {
                    list: [],
                    total: 0,
                },
                cg_id: 16,
                ptype_id: 14,
                loading: false,
                finished: false,
                timer: null,
                breadList: [
                    { name:'学员管理', path: '/caigouHome', active:1 },
                    { name:'添加学员', path: '/addKehu?cg_id=16', active:0 },
                    { name:'设置表单', path: '/projectTypeDesign?cg_id=16&ptype_id=14', active:0 },
                ],
                userType: 'caigou'
            }
        },
        computed: {
        },
        async created () {
            this.getListData();
        },
        methods: {
            getListData() {
                const param = {
                    page: this.page,
                    limit: this.limit,
                }
                getKehuList(param).then(resp => {
                    // 加载状态结束
                    this.loading = false;
                    this.tableData.total = resp.total;
                    if (resp.data.length > 0) {
                        resp.data.forEach((item) => {
                            this.tableData.list.push(item);
                        })
                    } else {
                        this.finished = true;
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            onLoad() {
                this.loading = false;
                if(!this.timer) {
                    this.timer = setTimeout(() => {
                        this.page++;
                        this.getListData();
                        this.timer = null;
                    }, 1000)
                }
            },
            viewDetail (val) {
                getKehuDetail({ id: val }).then(resp => {
                    if(resp.code == 200 || resp.code == 0) {
                        Dialog.alert({
                            title: '详情',
                            message: '<p style="text-align: left;">学员ID：' + resp.data.id + '</p>' +
                            '<p style="text-align: left;">学员姓名：' + resp.data.xy_name + '</p>' +
                            '<p style="text-align: left;">项目名称：' + resp.data.xy_source_text + '</p>' +
                            '<p style="text-align: left;">添加时间：' + resp.data.create_time + '</p>',
                        });
                    } else {
                        Dialog.alert({ message: resp.message });
                    }
                }).catch(error => {
                    console.log(error)
                })
            },
            onDelete(index, val) {
                Dialog.confirm({
                    title: '提示',
                    message: '确定删除？',
                }).then(() => {
                    // on confirm
                    deleteKehu({ id: val }).then(resp => {
                        if(resp.code == 200 || resp.code == 0) {
                            Dialog.alert({message: resp.message});
                            this.tableData.list.splice(index, 1);
                        } else {
                            Dialog.alert({ message: resp.message });
                        }
                    }).catch(error => {
                        console.log(error)
                    })
                }).catch(() => {
                    // on cancel
                });
            },
            onModify (val){
                this.$router.push({ path: '/addKehu', query: { id: val, cg_id: this.cg_id } })
            }
        }
    }
</script>

<style lang="less" scoped>
  * {
    box-sizing: border-box;
  }
  .list-border {
    border: 1px solid #D3E3FD;
    border-radius: 15px;
    padding-left: 10px;
  }

  @media screen and (max-width: 767.98px) {
    .list-font {
      font-size: 14px;
    }
  }
  @media screen and (max-width: 414px) {
    .list-font {
      font-size: 12px;
    }
  }
  .operator_a {
    text-decoration: underline;
  }
  .font_red {
    color: rgba(247, 40, 40, 1);
  }
  .font_blue {
    color: rgba(42, 130, 228, 1);
  }
  .font_green {
    color: rgba(28, 199, 96, 1);
  }
</style>
