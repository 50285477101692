import axios from 'axios'
import { Toast } from 'vant'
import { getToken } from './storage'

// 创建axios的实例
const request = axios.create({
  // 基地址
    //baseURL: 'http://dev.yxs:8080',
    baseURL: 'https://yxedu.ysjiaju.cn',
  // 请求的时间范围，如果超过了就会请求报错
  timeout: 10000
})

// 给实例添加拦截器
// 添加请求拦截器
request.interceptors.request.use(
  function (config) {
    // config就是请求的信息
    // 对config进行处理，然后发起请求
    // 请求发送之前给每一个请求头加上token
    const token = getToken()
    if (token) {
      config.headers.Authorization = token
    }

    // 在发送请求之前做些什么
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
request.interceptors.response.use(
  function (response) {
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么

    // 在每次响应数据返回的时候解构
    const { data } = response

    return data
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    if (error.response) {
      Toast.fail(error.response.data.message)
    }
    return Promise.reject(error)
  }
)
export default request
