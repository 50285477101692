<template>
  <div>
    <!-- 导航栏 -->
    <van-nav-bar title="添加/修改项目" left-arrow @click-left="onClickLeft" />
    <!-- 表单组件 -->
    <van-form :model="addForm" ref="dataForm">
      <van-field
              v-model="addForm.pname"
              name="pname"
              placeholder="请输入项目名称" label="项目名称" required clearable
              :rules="[
          { required: true, message: '请填写项目名称' }
        ]"
      />
      <van-field
              readonly
              clickable
              name="ptype"
              :value="addForm.ptypetxt"
              label="项目类别"
              placeholder="点击选择项目类别"
              @click="showTypePicker = true" clearable
      />
      <van-popup v-model="showTypePicker" position="bottom">
        <van-picker
                show-toolbar
                :columns="projectTypeList"
                value-key="tname"
                :default-index="ptypeSelectedIndex"
                @confirm="onTypeConfirm"
                @cancel="showTypePicker = false"
        />
      </van-popup>
      <van-field
              readonly
              clickable
              name="gtype"
              :value="addForm.gtypetxt"
              label="选择供应商"
              placeholder="点击选择供应商"
              @click="showGysPicker = true" clearable
      />
      <van-popup v-model="showGysPicker" position="bottom">
        <van-picker
                show-toolbar
                :columns="gysList"
                value-key="gname"
                :default-index="gtypeSelectedIndex"
                @confirm="onGysConfirm"
                @cancel="showGysPicker = false"
        />
      </van-popup>
      <van-field
        v-model="addForm.contact"
        name="contact"
        placeholder="请输入联系人" label="联系人" clearable
      />
      <van-field
              v-model="addForm.phone"
              name="phone"
              maxlength="11"
              placeholder="请输入手机" label="手机" required clearable
              :rules="[
          { required: true, message: '请填写手机' },{ pattern: /^1[3456789]\d{9}$/, message: '手机号码格式错误！'}
        ]"
      />
      <van-field
              v-model="addForm.psort"
              name="psort"
              placeholder="请输入排序" label="排序" clearable
      />
      <div style="margin: 16px;text-align: center;">
        <van-button type="info" @click="onSubmit()">确 认</van-button>
        <van-button color="#cccc" style="margin-left: 20px;" to="/project">返 回</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import { getProjectDetail, getSearchSelectList, saveProject } from "@/api/project";
import { Dialog } from 'vant';

export default {
  name: 'AddProject',
  data () {
    return {
      addForm: {
          id: 0,
          pname: '',
          ptype: '', //项目类别ID
          gtype: '', //供应商ID
          ptypetxt: '', //项目类别名称
          gtypetxt: '', //供应商名称
          contact: '',
          phone: '',
          psort: 0,
      },
      projectTypeList: [],
      showTypePicker: false,
      gysList: [],
      showGysPicker: false,
      field: 'project_type,gys',
      ptypeSelectedIndex: '0',
      gtypeSelectedIndex: '0',
    }
  },
  created() {
      //this.addForm.id = this.$route.params.id;
      this.addForm.id = this.$route.query.id
      if(this.addForm.id != undefined && this.addForm.id != '' && this.addForm.id != 0 ) {
          //修改获取信息
          this.getDetail();
      }
      this.getSearchSelectData();
  },
  methods: {
    async onSubmit () {
      this.$refs.dataForm.validate().then(() => {
          // 验证通过
          saveProject(this.addForm).then(resp => {
              Dialog.alert({message: resp.message}).then(() => {
                  this.$router.push('/project')
              });
          }).catch(error => {
              console.log(error)
          })
      }).catch(error => {
          console.log(error)
      });
    },
    getDetail () {
        getProjectDetail({ id: this.addForm.id }).then(resp => {
            if(resp.code == 200 || resp.code == 0) {
                this.addForm = resp.data;
            } else {
                console.log(resp.message)
            }
        }).catch(error => {
            console.log(error)
        })
    },
    getSearchSelectData () {
        getSearchSelectList({ field: this.field }).then(resp => {
            if(resp.code == 200 || resp.code == 0) {
                this.projectTypeList = resp.data.project_type_list;
                this.gysList = resp.data.gys_list;
                //获取默认选中
                if (resp.data.project_type_list.length > 0) {
                    resp.data.project_type_list.forEach((item, index) => {
                        if(item.id == this.addForm.ptype) {
                            this.ptypeSelectedIndex = index;
                        }
                    })
                }
                if (resp.data.gys_list.length > 0) {
                    resp.data.gys_list.forEach((item, index) => {
                        if(item.id == this.addForm.gtype) {
                            this.gtypeSelectedIndex = index;
                        }
                    })
                }
            } else {
                console.log(resp.message)
            }
        }).catch(error => {
            console.log(error)
        })
    },
    onTypeConfirm(value) {
        this.addForm.ptypetxt = value.tname;
        this.addForm.ptype = value.id;
        this.showTypePicker = false;
    },
    onGysConfirm(value) {
        this.addForm.gtypetxt = value.gname;
        this.addForm.gtype = value.id;
        this.showGysPicker = false;
    },
    onClickLeft () {
        this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>

</style>
